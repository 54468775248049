<template>
    <div>
        <QMyClientsSubmenu />
        <v-row class="mx-5">
            <v-col cols="12">
                <v-card>
                    <QCarrierPortalList :agent="user.Agent" />
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import QCarrierPortalList from '@/components/lists/QCarrierPortalList.vue'
import QMyClientsSubmenu from '@/components/navigation/Submenus/QMyClientsSubmenu.vue'

export default {
    components: {
        QMyClientsSubmenu,
        QCarrierPortalList,
    },
}
</script>
